import {
  MainAppModel
} from '../../../models/mainApp'
import Pagination from '../../../components/Pagination'
import formatTime from '../../../utils/formatTime'
import upload from '../../../utils/oss'
const mainAppModel = new MainAppModel()
const columns = [{
    title: '分组编号',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '分组名称',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {
      customRender: 'name'
    },
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    scopedSlots: {
      customRender: "created_at"
    }
  },
  {
    title: '操作',
    dataIndex: '',
    key: 'action',
    scopedSlots: {
      customRender: 'action'
    }
  }
];

var myMixin = {
  data() {
    return {
      form1: this.$form.createForm(this, {
        name: 'horizontal_login'
      }),
      data: [],
      columns,
      visible: false,
      formLayout: 'horizontal',
      form2: this.$form.createForm(this, {
        name: 'coordinated'
      }),
      flag: '',
      editId: 0,
      loading: false,
      total: 0,
      loading1: false,
      loading2: false,
      imageUrl1: '',
      imageUrl2: '',
      tempUrl1: '',
      tempUrl2: '',
      title: '',
      current:1

    };
  },

  created() {
    this.queryMainList()
  },

  methods: {

    handleById(record, flag) {
      this.flag = flag
      this.editId = record.id
      this.visible = true;
      this.imageUrl1 =  record.icon
      this.imageUrl2 =  record.f_icon
      this.title = "编辑"
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          name: record.name,
        })
      })
    },

    handleDelete(record) {
      if(record.apps.length > 0) {
        this.$confirm({
          content: '当前分组下存在软件，无法进行删除!',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {},
          onCancel() {},
        });
      } else {
        this.$confirm({
          content: '确定要删除吗？',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            mainAppModel.destroy(record.id).then((res) => {
              if (res.code == 0) {
                this.$notification.open({
                  message: '提示',
                  description: res.message,
                  duration: 2
                });
                this.queryMainList()
              }
            })
          },
          onCancel() {},
        });
      }
    },

    showModal(flag) {
      this.flag = flag
      this.visible = true;
      this.title = "新增"
      this.imageUrl1 = ''
      this.imageUrl2 = ''
    },

    handleChange(page) {
      this.current = page
      this.form1.validateFields((err, values) => {
        if (!err) {
          this.queryMainList(values.key, '', page)
        }
      });
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        values.icon = this.imageUrl1
        values.f_icon = this.imageUrl2
        if (!err) {
          if (this.flag == 'new') {
            mainAppModel.create(values).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: '提示',
                  description: res.message,
                  duration: 2
                });
                this.queryMainList()
              }
            })
          } else {
            mainAppModel.update(values, this.editId).then((res) => {
              if (res.code == 0) {
                this.visible = false;
                this.$notification.open({
                  message: '提示',
                  description: res.message,
                  duration: 2
                });
                this.queryMainList()
              }
            })
          }
          this.form2.resetFields();
        }
      });
    },

    handleCancel() {
      console.log(this.form2)
      this.form2.resetFields();
      this.imageUrl2 = ""
      this.imageUrl1 = ""
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : ''
          this.queryMainList(key, '', 1)
        }
      });
    },

    handleClear() {
      this.queryMainList()
      this.form1.resetFields();
    },

    getDetail(record) {
      this.$router.push({
        path: `/board/main_apps/${record.id}`,
        query: {
          name: `${record.name}`
        }
      })
    },

    beforeUpload1(file) {
      this.loading1 = true
      upload(file, "softmall-images", {}).then(res => {
        this.loading1 = false
        if(res) {
          this.imageUrl1 = res
        }
      })
    },

    beforeUpload2(file) {
      this.loading2 = true
      upload(file, "softmall-images", {}).then(res => {
        this.loading2 = false
        if(res) {
          this.imageUrl2 = res
        }
      })
    },

    queryMainList(key, all, page) {
      this.loading = true
      mainAppModel.index(key, all, page).then((res) => {
        if (res.code == 0) {
          this.current = page
          res.result.map((item, index) => {
            item.key = index
            item.created_at = formatTime(item.created_at)
            return item
          })
          this.data = res.result
          this.loading = false
          this.total = res.total
        }
      })
    },
  },

  components: {
    Pagination
  }
};

export default myMixin