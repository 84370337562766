<template>
  <div>
    <a-form
      layout="inline"
      :form="form1"
      @submit="handleSearch">
      <a-form-item>
        <a-input
          v-decorator="[
          'key'
        ]"
          placeholder="请输入分组名称"
          autocomplete="off">
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          html-type="submit">
          搜索
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button
          type="danger"
          @click="handleClear"
          html-type="button">
          清空
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button
          type="primary"
          @click="showModal('new')">
          新增分组
        </a-button>
      </a-form-item>
    </a-form>
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading">
      <template
        slot="name"
        slot-scope="text">{{text}}</template>
      <span
        slot="action"
        slot-scope="text, record">
        <a href="javascript:;"
          @click="handleById(record, 'update')">编辑</a>
        <a-divider
          type="vertical" />
        <a href="javascript:;"
          @click="handleDelete(record)"
          class="delete">删除</a>
      </span>
    </a-table>
    <Pagination
      :current="current"
      :total="total"
      @handleChange="handleChange"
      v-show="total > 20" />
    <a-modal
      :title="title"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
      width="450px">
      <a-form
        :form="form2"
        :label-col="{ span: 6}"
        :wrapper-col="{ span: 18 }">
        <a-form-item
          label="分组名称">
          <a-input
            v-decorator="['name', {rules: [{ required: true, message: '请输入分组名称!' }] }]" />
        </a-form-item>
        <a-form-item
          label="分组图片">
          <span
            class="redFont">支持图片格式：PNG、JPG、GIF、JPEG，10M以内，推荐图片尺寸280*170px，推荐图标尺寸64*64px</span>
          <a-upload
            name="icon"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload1">
            <img
              v-if="imageUrl1"
              :src="imageUrl1"
              alt="avatar" />
            <div
              v-else>
              <a-icon
                :type="loading1 ? 'loading' : 'plus'" />
              <div
                class="ant-upload-text">
                点击上传
              </div>
            </div>
          </a-upload>
          <a-upload
            name="f_icon"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload2">
            <img
              v-if="imageUrl2"
              :src="imageUrl2"
              alt="avatar" />
            <div
              v-else>
              <a-icon
                :type="loading2 ? 'loading' : 'plus'" />
              <div
                class="ant-upload-text">
                点击上传
              </div>
            </div>
          </a-upload>
          <div
            class="upload-word">
            <span>系列图标-正常</span>
            <span>系列图标-焦点</span>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import myMixin from "./mixin";
export default {
  mixins: [myMixin]
};
</script>

<style lang="scss" scoped>
.ant-upload-picture-card-wrapper {
  width: 110px;
  margin-right: 10px;
}
.ant-upload.ant-upload-select-picture-card {
  margin-bottom: -14px;
  img {
    width: 100%;
  }
}
.avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
  float: left;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.upload-word span {
  width: 110px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  font-size: 13px;
  color: #333;
}
</style>
