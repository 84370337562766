import {HTTP} from '../utils/axios'

class MainAppModel extends HTTP {
  /*首页渲染*/
  index(key = '', all = '', page = 1) {
      return this.request({
          url: 'board/main_apps',
          method: "GET",
          data: {
            key: key,
            all: all,
            page: page
          }
      })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/main_apps",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/main_apps/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy(id) {
    return this.request({
        url: 'board/main_apps/' + id,
        method: "DELETE"
    })
  }
}

export {MainAppModel}